import { useCurrentAuthenticatedUser } from '~/composables/user.store';
import { match } from 'ts-pattern';
import 'moment/dist/locale/fr';

export default defineNuxtPlugin(async () => {
  // We shouldn't try to load persisted data during SSR
  // (as we don't have access to localstorage)
  if (import.meta.server) {
    return;
  }

  const { tryLoadingPersistedData } = useCurrentAuthenticatedUser();
  await tryLoadingPersistedData();

  const router = useRouter();
  router.beforeEach(async (to, from, next) => {
    // Ignorer le traitement côté serveur
    if (import.meta.server) return next();

    try {
      const { isAuthenticated } = useCurrentAuthenticatedUser();

      const publicRoutes = ['/', '/adminasuser', '/motdepasse-oublie', '/renouveler-motdepasse'];

      return (
        match({ isPublicRoute: publicRoutes.includes(to.path), isAuthenticated })
          .with({ isPublicRoute: true, isAuthenticated: true }, () => next({ path: '/mon-tableau-de-bord' }))
          .with({ isPublicRoute: false, isAuthenticated: false }, () => next({ path: '/' }))
          // OK, keep routing going...
          .with({ isPublicRoute: true, isAuthenticated: false }, () => next())
          .with({ isPublicRoute: false, isAuthenticated: true }, () => next())
          .exhaustive()
      );
    } catch (err) {
      console.error(`Error during user-authenticated navigation guard:`, err);
      return next({ path: '/' });
    }
  });
});
