import type { HttpCallResult } from '~/utils/http';
import { httpCallWithResponseHandler } from '~/utils/http';
import type { ExpirableJWTTokens, TokenData } from '#shared/types';
import { match } from 'ts-pattern';
import moment from 'moment';
import { decodeJwt } from 'jose';

export type PasswordAuthParams = { grant_type: 'password'; username: string; password: string };
export type RefreshTokenAuthParams = { grant_type: 'refresh_token'; refresh_token: string };

export type AuthenticationParams = PasswordAuthParams | RefreshTokenAuthParams;

function toExpirableJWTToken(token: TokenData) {
  const accessTokenPayload = decodeJwt(token.access_token);
  const expirableToken: ExpirableJWTTokens = {
    refresh_token: token.refresh_token,
    access_token: token.access_token,
    refresh_token_expired_by: moment().add(token.expires_in, 'seconds').toISOString(),
    access_token_expired_by: moment((accessTokenPayload.exp || 0) * 1000).toISOString(),
  };

  return expirableToken;
}

export async function authenticate(
  authenticationParams: AuthenticationParams,
): Promise<HttpCallResult<ExpirableJWTTokens, Error>> {
  const config = useRuntimeConfig();

  const result = await httpCallWithResponseHandler<TokenData, Error>(
    `user/api/oidc/token`,
    {
      method: 'POST',
      withAuthorizationToken: 'none',
      body: {
        client_id: config.public.CLIENT_ID,
        client_secret: config.public.CLIENT_SECRET,
        ...authenticationParams,
      },
    },
    async (resp) => {
      if (!resp.ok) {
        const errorMessage = await resp.text();
        return { next: 'early-failure-return' as const, data: new Error(errorMessage) };
      }

      return { next: 'proceed-normally' as const };
    },
  );

  return match(result)
    .with({ type: 'success' }, (successfulResult) => {
      return {
        ...successfulResult,
        data: toExpirableJWTToken(successfulResult.data),
      };
    })
    .otherwise((errorResult) => errorResult);
}

export type ImpersonateAuthParams = Omit<PasswordAuthParams, 'grant_type'> & { impersonatedUserEmail: string };

export async function impersonatedAuth(
  params: ImpersonateAuthParams,
): Promise<HttpCallResult<{ token: ExpirableJWTTokens; impersonatedToken: ExpirableJWTTokens }, Error>> {
  const authResult = await authenticate({
    grant_type: 'password',
    username: params.username,
    password: params.password,
  });

  if (authResult.type === 'success') {
    const config = useRuntimeConfig();

    const impersonationToken = await httpCall<TokenData>(`user/api/oidc/impersonate`, {
      method: 'POST',
      withAuthorizationToken: 'providedToken',
      token: authResult.data,
      body: {
        client_id: config.public.CLIENT_ID,
        client_secret: config.public.CLIENT_SECRET,
        username: params.impersonatedUserEmail,
      },
    });

    return {
      type: 'success',
      data: { token: authResult.data, impersonatedToken: toExpirableJWTToken(impersonationToken) },
    };
  } else {
    return authResult;
  }
}
