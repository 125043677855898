import type { EquipmentCollectionName, Equipments } from '#shared/types';

export async function fetchUserEquipments({ userId }: { userId: string }) {
  return httpCall<Equipments>(`/api/equipments/byUser`, {
    withAuthorizationToken: 'configAppToken',
    method: 'GET',
    queryParams: {
      userId,
    },
  });
}

export async function updateEquipmentCustomName({
  collectionId,
  userId,
  collection,
  customName,
}: {
  collectionId: string;
  userId: string;
  collection: EquipmentCollectionName;
  customName: string;
}) {
  return httpCall<Equipments>(`/api/equipments/updateName`, {
    withAuthorizationToken: 'configAppToken',
    method: 'POST',
    body: {
      id: collectionId,
      custom_name: customName,
      collection,
      user_id: userId,
    },
  });
}
