import { defineStore } from 'pinia';

export const useAllStores = defineStore('allStores', () => {
  const router = useRouter();

  const resetAllStores = async ({ redirectToLoginPage }: { redirectToLoginPage: boolean }) => {
    // Late initialization of every store (so that every stores are not loaded on startup)
    const [{ useInvoices }, { useCurrentAuthenticatedUser }, { useEquipments }, { useCharges }, { useChargeCharts }] =
      await Promise.all([
        import('~/composables/invoices.store'),
        import('~/composables/user.store'),
        import('~/composables/equipments.store'),
        import('~/composables/charges.store'),
        import('~/composables/charge-charts.store'),
      ]);

    const { reset: resetInvoicesStore } = useInvoices();
    const { resetCurrentAuthenticatedUser, initiateCurrentAuthenticatedUserDeferredReset } =
      useCurrentAuthenticatedUser();
    const { resetUserEquipments } = useEquipments();
    const { resetCachedCharges } = useCharges();
    const { resetCachedChargeCharts } = useChargeCharts();

    resetInvoicesStore();
    resetUserEquipments();
    resetCachedCharges();
    resetCachedChargeCharts();

    if (redirectToLoginPage) {
      const { onceNoVueReliesOnAuthenticatedUser } = initiateCurrentAuthenticatedUserDeferredReset();
      await router.push('/');

      // Let's defer a little bit the authenticated user reset
      // because we might still be on a vue requiring an authenticated user and we should let
      // some time to Vue Router to route to login screen
      // Otherwise we will get vue rendering issues due to undefined authenticated user
      setTimeout(() => onceNoVueReliesOnAuthenticatedUser(), 500);
    } else {
      resetCurrentAuthenticatedUser();
    }
  };

  return {
    resetAllStores,
  };
});
