import type { ExpirableJWTTokens, User } from '#shared/types';
import { httpCallWithEmptyResponse } from '~/utils/http';

export async function fetchUserInfosForToken(token: ExpirableJWTTokens) {
  return httpCall<User>('/user/api/oidc/userinfo', {
    withAuthorizationToken: 'providedToken',
    token,
    method: 'GET',
  });
}

export async function fetchUserInfos() {
  return httpCall<User>('/user/api/oidc/userinfo', {
    withAuthorizationToken: 'userAccessToken',
    method: 'GET',
  });
}

export async function updateEmail({ email }: { email: string }) {
  return httpCallWithEmptyResponse('/user/api/account/update-email', {
    withAuthorizationToken: 'userAccessToken',
    method: 'POST',
    body: {
      email,
    },
  });
}

export async function updatePassword({ newPassword }: { newPassword: string }) {
  return httpCallWithEmptyResponse('/user/api/account/update-password', {
    withAuthorizationToken: 'userAccessToken',
    method: 'POST',
    body: {
      password: newPassword,
    },
  });
}
