import { defineStore } from 'pinia';
import { ref } from 'vue';
import type {
  EquipmentCollectionName,
  EquipmentPersona,
  Equipments,
  GestionnaireEquipments,
  UserEquipments,
} from '~/shared/types';
import { fetchUserEquipments, updateEquipmentCustomName } from '~/services/equipment.service';

export const useEquipments = defineStore('equipments', () => {
  // private props
  const _equipments = ref<Equipments | undefined>(undefined);
  const resetUserEquipments = () => {
    _equipments.value = undefined;
  };

  // readonly/calculated props
  const definedEquipments = computed(() => {
    if (!_equipments.value) {
      throw new Error(`definedEquipments() called with an undefined equipments value !`);
    }

    return _equipments.value;
  });
  const availablePersonas = computed<Array<EquipmentPersona>>(() => {
    const equipments = toValue(_equipments);
    if (!equipments) {
      return [];
    }

    return ([] as Array<'user' | 'gestionnaire'>)
      .concat(equipments.user.bornes.length + equipments.user.badges.length > 0 ? ['user'] : [])
      .concat(
        equipments.gestionnaire.bornes.length + equipments.gestionnaire.badges.length > 0 ? ['gestionnaire'] : [],
      );
  });

  const currentEquipmentPersonaRef = ref<EquipmentPersona>('user');
  watch([availablePersonas], ([availablePersonas]) => {
    if (currentEquipmentPersonaRef.value === 'user' && !availablePersonas.includes('user')) {
      currentEquipmentPersonaRef.value = 'gestionnaire';
    }
    if (currentEquipmentPersonaRef.value === 'gestionnaire' && !availablePersonas.includes('gestionnaire')) {
      currentEquipmentPersonaRef.value = 'user';
    }
  });

  const switchPersona = () => {
    currentEquipmentPersonaRef.value = toValue(currentEquipmentPersonaRef) === 'user' ? 'gestionnaire' : 'user';
  };
  const currentPersonaEquipments = computed<UserEquipments | GestionnaireEquipments>(() => {
    const isUser = toValue(currentEquipmentPersonaRef) === 'user',
      currentEquipments = toValue(definedEquipments);

    return isUser ? currentEquipments.user : currentEquipments.gestionnaire;
  });

  // actions
  const fetchUserEquipmentForUser = async (userId: string) => {
    if (!_equipments.value) {
      _equipments.value = await fetchUserEquipments({ userId });
    }

    return _equipments.value;
  };

  const updatePersonalName = async ({
    collectionId,
    userId,
    collection,
    customName,
  }: {
    collectionId: string;
    userId: string;
    collection: EquipmentCollectionName;
    customName: string;
  }) => {
    const updatedEquipment = await updateEquipmentCustomName({ collectionId, userId, collection, customName });

    _equipments.value = updatedEquipment;
  };

  const perPersonasEquipmentIdsRef = computed<
    Record<EquipmentPersona, undefined | { borneIds: string[]; badgeIds: string[]; driverEmaIds: string[] }>
  >(() => {
    const equipments = toValue(_equipments);

    return {
      gestionnaire: equipments
        ? {
            borneIds: equipments.gestionnaire.bornes.map((borne) => borne.id),
            badgeIds: equipments.gestionnaire.badges.map((badge) => badge.id),
            driverEmaIds: equipments.gestionnaire.drivers.map((driver) => driver.ema_id),
          }
        : undefined,
      user: equipments
        ? {
            borneIds: equipments.user.bornes.map((borne) => borne.id),
            badgeIds: equipments.user.badges.map((badge) => badge.id),
            driverEmaIds: equipments.user.drivers.map((driver) => driver.ema_id),
          }
        : undefined,
    };
  });

  const currentPersonaEquipmentIdsRef = computed(() => {
    const perPersonasEquipmentIds = toValue(perPersonasEquipmentIdsRef),
      currentEquipmentPersona = toValue(currentEquipmentPersonaRef);

    return perPersonasEquipmentIds[currentEquipmentPersona];
  });

  return {
    definedEquipments,
    availablePersonas,
    currentEquipmentPersona: currentEquipmentPersonaRef,
    switchPersona,
    currentPersonaEquipments,
    fetchUserEquipmentForUser,
    resetUserEquipments,
    updatePersonalName,
    perPersonasEquipmentIdsRef,
    currentPersonaEquipmentIdsRef,
  };
});
