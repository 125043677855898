import { defineStore } from 'pinia';
import type { ExpirableJWTTokens, User } from '~/shared/types';
import { authenticate as authenticateService, impersonatedAuth } from '~/services/auth.service';
import { fetchUserInfos, fetchUserInfosForToken } from '~/services/user.service';
import { P, match } from 'ts-pattern';
import moment from 'moment';
import { useLocalStoragePersistedRef } from '~/utils/localstorage';
import { navigateTo } from '#app/composables/router';
import { useAllStores } from '~/composables/all-stores.store';

export type AuthResult = { type: 'success'; data: User } | { type: 'error'; error: Error };

export const useCurrentAuthenticatedUser = defineStore('currentAuthenticatedUser', () => {
  // private props
  const [_currentUserToken, readCurrentUserToken] = useLocalStoragePersistedRef<ExpirableJWTTokens | undefined>(
    'user-auth-token',
    undefined,
  );
  const [_currentlyAuthenticatedUser, readCurrentAuthenticatedUser] = useLocalStoragePersistedRef<User | undefined>(
    'user-infos',
    undefined,
  );

  // readonly/calculated props
  const isAuthenticated = computed(() => {
    const authenticatedUser = toValue(currentlyAuthenticatedUser),
      currentAuthenticatedUserResetInProgress = toValue(_currentAuthenticatedUserResetInProgressRef);

    return authenticatedUser !== undefined && !currentAuthenticatedUserResetInProgress;
  });
  const currentlyAuthenticatedUser = computed(() => {
    const [currentUserToken, currentAuthenticatedUser] = [
      toValue(_currentUserToken),
      toValue(_currentlyAuthenticatedUser),
    ];
    if (!currentUserToken || isUserTokenExpired(currentUserToken)) {
      return undefined;
    }

    return currentAuthenticatedUser;
  });

  // Aimed at being called on guarded views supposed to have an authenticated user and where
  // this is a no-question to have undefined current authenticated user
  const definedCurrentAuthenticatedUser = computed(() => {
    const currentAuthenticatedUser = toValue(_currentlyAuthenticatedUser);

    if (!currentAuthenticatedUser) {
      console.error(`Unexpected call to definedCurrentAuthenticatedUser() on clientside with an undefined user !`);
      throw new Error(`Unexpected call to definedCurrentAuthenticatedUser() on clientside with an undefined user !`);
    }

    return currentAuthenticatedUser;
  });

  // actions
  const authenticate = async ({ username, password }: { username: string; password: string }): Promise<AuthResult> => {
    const authResult = await authenticateService({
      grant_type: 'password',
      username,
      password,
    });

    if (authResult.type === 'success') {
      await useAllStores().resetAllStores({ redirectToLoginPage: false });

      _currentUserToken.value = authResult.data;

      const userInfos = await fetchUserInfos();
      await onceUserInfosAvailable(_currentlyAuthenticatedUser, userInfos);

      return { type: 'success', data: userInfos };
    } else {
      return authResult;
    }
  };
  const impersonatedAuthenticate = async ({
    username,
    password,
    impersonatedUserEmail,
  }: {
    username: string;
    password: string;
    impersonatedUserEmail: string;
  }): Promise<AuthResult> => {
    const authResult = await impersonatedAuth({
      username,
      password,
      impersonatedUserEmail,
    });

    if (authResult.type === 'success') {
      const userInfos = await fetchUserInfosForToken(authResult.data.token);
      if (!userInfos.user_roles.includes('admin')) {
        return {
          type: 'error',
          error: new Error(`Vous n'êtes pas autorisé à vous connecter à cette page`),
        };
      }

      await useAllStores().resetAllStores({ redirectToLoginPage: false });

      _currentUserToken.value = authResult.data.impersonatedToken;

      const impersonatedUserInfos = await fetchUserInfos();
      await onceUserInfosAvailable(_currentlyAuthenticatedUser, impersonatedUserInfos);

      return { type: 'success', data: impersonatedUserInfos };
    } else {
      return authResult;
    }
  };

  const resolveValidAccessToken = async () => {
    const currentToken = toValue(_currentUserToken);

    // Undefined / Expired refresh token => no access token retrieved !
    if (!currentToken || moment().isAfter(currentToken.refresh_token_expired_by)) {
      return undefined;
    }

    // Expired access token => let's retrieve a new one
    if (moment().isAfter(currentToken.access_token_expired_by)) {
      console.log(`Expired access token detected => gathering a new one...`);
      const refreshTokenResult = await authenticateService({
        grant_type: 'refresh_token',
        refresh_token: currentToken.refresh_token,
      });

      if (refreshTokenResult.type === 'success') {
        _currentUserToken.value = refreshTokenResult.data;

        return refreshTokenResult.data.access_token;
      } else {
        console.error(`Invalid refresh token processed !`);
        resetCurrentAuthenticatedUser();
        return undefined;
      }
    }

    // Valid access token => return it
    return currentToken.access_token;
  };

  const fillAuthorizationHeader = async (headers: Record<string, string>) => {
    const maybeAccessToken = await resolveValidAccessToken();
    if (maybeAccessToken) {
      headers['Authorization'] = `Bearer ${maybeAccessToken}`;
    } else {
      // Redirect to login screen
      navigateTo({ path: '/' });
    }
  };

  const resetCurrentAuthenticatedUser = () => {
    _currentUserToken.value = undefined;
    _currentlyAuthenticatedUser.value = undefined;
  };
  const _currentAuthenticatedUserResetInProgressRef = ref(false);
  const initiateCurrentAuthenticatedUserDeferredReset = () => {
    _currentUserToken.value = undefined;
    _currentAuthenticatedUserResetInProgressRef.value = true;

    return {
      onceNoVueReliesOnAuthenticatedUser: () => {
        _currentlyAuthenticatedUser.value = undefined;
        _currentAuthenticatedUserResetInProgressRef.value = false;
      },
    };
  };

  const tryLoadingPersistedData = async () => {
    const tokenData = readCurrentUserToken();
    if (!tokenData || isUserTokenExpired(tokenData)) {
      resetCurrentAuthenticatedUser();

      return undefined;
    }
    _currentUserToken.value = tokenData;

    const maybePersistedUserData = readCurrentAuthenticatedUser();
    const userInfos = await match(maybePersistedUserData)
      .with(P.nullish, () => fetchUserInfos())
      .otherwise((persistedUserData) => persistedUserData);

    // let's refresh user data in the background and quickly returning "cached" (persisted) user data
    // (no `await` here on purpose)
    await onceUserInfosAvailable(_currentlyAuthenticatedUser, userInfos);

    return userInfos;
  };

  const isUserTokenExpired = (token: ExpirableJWTTokens) => {
    return moment().isAfter(moment(token.refresh_token_expired_by));
  };

  return {
    isAuthenticated,
    currentlyAuthenticatedUser,
    definedCurrentAuthenticatedUser,
    authenticate,
    impersonatedAuthenticate,
    tryLoadingPersistedData,
    fillAuthorizationHeader,
    resetCurrentAuthenticatedUser,
    initiateCurrentAuthenticatedUserDeferredReset,
  };
});

async function onceUserInfosAvailable(
  currentlyAuthenticatedUserRef: Ref<User | undefined>,
  existingUserInfos: User,
): Promise<void> {
  // It is important to set this here, so that fetchCurrentUserEquipment()
  // can rely on user id, even if we re-set ref value once we get user details
  currentlyAuthenticatedUserRef.value = existingUserInfos;
  await useEquipments().fetchUserEquipmentForUser(existingUserInfos.user_id);
}
