import type { JSONValue } from 'proto3-json-serializer';
import { useCurrentAuthenticatedUser } from '~/composables/user.store';
import { uuidv4 } from '@firebase/util';
import { match } from 'ts-pattern';
import type { ExpirableJWTTokens } from '#shared/types';

export type BaseApiCallParams = {
  queryParams?: Record<string, string>;
} & (
  | { withAuthorizationToken: 'userAccessToken' | 'configAppToken' | 'none' }
  | { withAuthorizationToken: 'providedToken'; token: ExpirableJWTTokens }
);

export type ApiCallParams = BaseApiCallParams &
  ({ method: 'GET' | 'DELETE' } | { method: 'POST' | 'PUT'; body?: JSONValue });

export type HttpCallResult<RESULT, ERROR = never> = { type: 'success'; data: RESULT } | { type: 'error'; error: ERROR };

export async function httpCall<RESULT>(url: string, params: ApiCallParams): Promise<RESULT> {
  const result = await httpCallWithResponseHandler<RESULT>(url, params);

  if (result.type === 'success') {
    return result.data;
  } else {
    throw result.error;
  }
}

export async function httpCallWithEmptyResponse(url: string, params: ApiCallParams) {
  const result = await httpCallWithResponseHandler<null, null>(url, params, async (resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      return { next: 'early-success-return', data: null };
    }
    return { next: 'early-failure-return', data: null };
  });

  if (result.type === 'success') {
    return null;
  } else {
    throw result.error;
  }
}

export type ResponseHandler<RESULT, ERROR> = (
  resp: Response,
) => Promise<
  | { next: 'early-success-return'; data: RESULT }
  | { next: 'early-failure-return'; data: ERROR }
  | { next: 'proceed-normally' }
>;

export async function httpCallWithResponseHandler<RESULT, ERROR = never>(
  url: string,
  params: ApiCallParams,
  responseHandler: ResponseHandler<RESULT, ERROR> | undefined = undefined,
): Promise<HttpCallResult<RESULT, ERROR>> {
  const { fillAuthorizationHeader } = useCurrentAuthenticatedUser();

  const urlWithParams = `${url}${params.queryParams ? `${url.includes('?') ? '&' : '?'}${new URLSearchParams(params.queryParams).toString()}` : ``}`;

  const headers: Record<string, string> = {
    'X-Request-ID': uuidv4(),
    'X-Correlation-ID': uuidv4(),
  };
  const additionnalParams: Partial<RequestInit> = {};

  if (params.method === 'POST' || params.method === 'PUT') {
    headers['Content-Type'] = 'application/json';
    additionnalParams.body = params.body ? JSON.stringify(params.body) : undefined;
  }

  await match(params)
    .with({ withAuthorizationToken: 'userAccessToken' }, () => fillAuthorizationHeader(headers))
    .with({ withAuthorizationToken: 'configAppToken' }, async () => {
      const config = useRuntimeConfig();
      headers['Authorization'] = `Bearer ${config.public.APP_TOKEN}`;
    })
    .with({ withAuthorizationToken: 'providedToken' }, async (params) => {
      headers['Authorization'] = `Bearer ${params.token.access_token}`;
    })
    .with({ withAuthorizationToken: 'none' }, () => {
      // No-op
    })
    .exhaustive();

  const resp = await fetch(urlWithParams, {
    method: params.method,
    headers,
    ...additionnalParams,
  });

  if (responseHandler) {
    const responseHandlerResult = await responseHandler(resp);
    if (responseHandlerResult.next === 'early-success-return') {
      return { type: 'success', data: responseHandlerResult.data };
    } else if (responseHandlerResult.next === 'early-failure-return') {
      return { type: 'error', error: responseHandlerResult.data };
    }
  }

  if (!resp.ok) {
    // if no response handler is provided with early-returns,
    // default behaviour will be to throw an error with http call context
    throw new Error(`Error while calling ${params.method} ${urlWithParams}:\n  ${await resp.text()}`);
  }

  const jsonResponse: RESULT = await resp.json();
  return { type: 'success', data: jsonResponse };
}
