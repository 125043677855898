import { match } from 'ts-pattern';

export function useLocalStoragePersistedRef<T>(
  localStorageItemName: string,
  initialRefValue: T,
  valueWhenReadingDuringSSR: 'throw-exception' | 'return-initial-value' = 'throw-exception',
): [Ref<T>, () => T] {
  const reference = ref<T>(initialRefValue) as Ref<T>;

  // given that localstorage is not accessible during SSR, we should prevent
  // this to happen as this would be useless
  if (!import.meta.server) {
    watch([reference], ([referenceValue]) => {
      if (referenceValue !== undefined && referenceValue !== null) {
        localStorage.setItem(localStorageItemName, JSON.stringify(referenceValue));
      } else {
        localStorage.removeItem(localStorageItemName);
      }
    });
  }

  const readPersistedData = () => {
    // in case of SSR, we should consider that the value is not readable
    if (import.meta.server) {
      return match(valueWhenReadingDuringSSR)
        .with('return-initial-value', () => initialRefValue)
        .with('throw-exception', () => {
          throw new Error(
            `Unexpected call to readPersistedData() in a SSR context for localStorageItemName=${localStorageItemName}`,
          );
        })
        .exhaustive();
    }

    try {
      const rawData = localStorage.getItem(localStorageItemName);
      const data = rawData ? (JSON.parse(rawData) as T) : initialRefValue;
      return data;
    } catch (err) {
      console.error(
        `Error while parsing JSON data for ${localStorageItemName}, falled back to ${initialRefValue}:`,
        err,
      );
      return initialRefValue;
    }
  };

  return [reference, readPersistedData];
}
